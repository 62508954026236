html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, summary, time, mark, audio, video{
  margin:0;
  padding:0;
  border:0;
  font-size:100%;
  vertical-align:baseline;
  outline:none;
}
html,body{height:100%;}
body{ margin:0; min-width:320px;  transition: all 0.3s ease;position:relative;}
article, aside, details, figcaption, figure, footer, header, menu, nav, section{ display:block; }
ol, ul{ list-style:none; }
blockquote, q{ quotes:none; }
blockquote:before, blockquote:after, q:before, q:after{ content:none; }
/* strong{ font-weight:800; } */
input{ outline:none; }
table{ border-collapse:collapse; border-spacing:0; }
img{ border:0; max-width:100%; vertical-align: middle}
a{ text-decoration:none; transition:all linear 0.19s; }
a:hover{ text-decoration:none; }
/* a.md-default-theme:not(.md-button), a:not(.md-button){color: #3d3d41;}
a.md-default-theme:hover:not(.md-button), a:hover:not(.md-button) {color: #999999;} */
table{margin:auto;}
table tr:nth-child(even){/*background-color:#cde1ef;*/}
table tr th{padding:9px 10px; width:300px;text-align:left;font-weight:800;}
table tr td{padding:9px 10px; text-align:left;}
li.disc{list-style: inside none disc;}
input[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  box-sizing: content-box; /* 2 */
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
sup{vertical-align: super;}
strong{font-weight:800;
}

ul li{
  list-style-type: none;
  /*font-size:12px;*/
  margin-bottom:24px;
  /*padding-left:20px;*/
  position: relative;
}
/*ul li::before{
  position: absolute;
  content: "";
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background-color: black;
  left: 8px;
  top: 8px;
  right: auto;
  bottom: auto;
}*/
ul li:last-child{margin-bottom:0;}
@media (min-width: 979px) {
  @viewport {
    user-zoom: fixed;
  }
}
